import {Checkout, ShippingOption} from '@wix/ambassador-ecom-v1-checkout/types';
import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ItemTypePreset} from './ItemType.model';
import {PriceSummaryModel} from './PriceSummary.model';
import {GiftCardModel} from './GiftCard.model';
import {AdditionalFeeModel} from './AdditionalFee.model';
import {CustomFieldModel} from './CustomField.model';
import {BuyerInfoModel} from './BuyerInfo.model';
import {AddressWithContactModel} from './AddressWithContact.model';
import {ShippingOptionModel} from './ShippingOption.model';
import {CalculationErrorsModel} from './CalculationErrors.model';
import {PriceModel} from './Price.model';

export class CheckoutModel {
  public id: string;
  public cartId?: string;
  public hasDigitalItems: boolean;
  public buyerNote?: string;
  public buyerLanguage?: string;
  public channelType?: string;
  public siteLanguage?: string;
  public lineItems: LineItemModel[];
  public numberOfItems: number;
  public appliedCoupon?: AppliedCouponModel;
  public hasShippableItems: boolean;
  public hasItemsWithLongPrice: boolean;
  public hasSubscriptionItems: boolean;
  public itemTypes: Set<ItemTypePreset>;
  public priceSummary: PriceSummaryModel;
  public payNowTotalAfterGiftCard: PriceModel;
  public giftCard?: GiftCardModel;
  public additionalFees: AdditionalFeeModel[];
  public customField?: CustomFieldModel;
  public buyerInfo: BuyerInfoModel;
  public billingInfo?: AddressWithContactModel;
  public shippingDestination?: AddressWithContactModel;
  public selectedShippingOption?: ShippingOptionModel;
  public shippingOptions: ShippingOptionModel[];
  public pickupOptions: ShippingOptionModel[];
  public currency?: string;
  public calculationErrors: CalculationErrorsModel;

  constructor(checkout: Checkout) {
    this.id = checkout.id!;
    this.cartId = checkout.cartId ?? undefined;
    this.buyerLanguage = checkout.buyerLanguage;
    this.channelType = checkout.channelType;
    this.siteLanguage = checkout.siteLanguage ?? undefined;
    this.buyerNote = checkout.buyerNote ?? undefined;
    this.lineItems = (checkout.lineItems ?? []).map((lineItem) => new LineItemModel(lineItem));
    this.hasDigitalItems = this.lineItems.some((lineItem) => lineItem.itemType.preset === ItemTypePreset.DIGITAL);
    this.numberOfItems = this.lineItems.reduce((acc: number, {quantity}: LineItemModel) => acc + quantity, 0);
    this.appliedCoupon = AppliedCouponModel.fromDTO(checkout.appliedDiscounts);
    this.hasShippableItems = this.lineItems.some(
      ({itemType}) => itemType.preset === ItemTypePreset.PHYSICAL || itemType.preset === ItemTypePreset.UNRECOGNISED
    );
    this.hasItemsWithLongPrice = this.lineItems.some((lineItem) => lineItem.prices.isLongPrice);
    this.hasSubscriptionItems = this.lineItems.some((lineItem) => lineItem.subscription);
    this.itemTypes = new Set(
      this.lineItems.filter(({itemType}) => itemType.preset).map(({itemType}) => itemType.preset!)
    );
    this.priceSummary = new PriceSummaryModel(checkout.priceSummary);
    this.payNowTotalAfterGiftCard = new PriceModel(checkout.payNowTotalAfterGiftCard);
    this.giftCard = checkout.giftCard ? new GiftCardModel(checkout.giftCard) : undefined;
    this.additionalFees = (checkout.additionalFees ?? []).map((additionalFee) => new AdditionalFeeModel(additionalFee));
    this.customField = checkout.customFields?.[0] ? new CustomFieldModel(checkout.customFields?.[0]) : undefined;
    this.buyerInfo = new BuyerInfoModel(checkout.buyerInfo);
    this.billingInfo = checkout.billingInfo ? new AddressWithContactModel(checkout.billingInfo) : undefined;
    this.shippingDestination = checkout.shippingInfo?.shippingDestination
      ? new AddressWithContactModel(checkout.shippingInfo?.shippingDestination)
      : undefined;
    this.selectedShippingOption = checkout.shippingInfo?.selectedCarrierServiceOption
      ? new ShippingOptionModel(checkout.shippingInfo?.selectedCarrierServiceOption)
      : undefined;

    const allOptions = getFlattenedShippingOptions(checkout);
    this.shippingOptions = getOptionModels(allOptions.filter((option) => !isPickupOption(option)));
    this.pickupOptions = getOptionModels(allOptions.filter(isPickupOption));
    this.currency = checkout.currency;
    this.calculationErrors = new CalculationErrorsModel(this.lineItems, checkout.calculationErrors);
  }
}

function isPickupOption({logistics}: ShippingOption): boolean {
  return !!logistics?.pickupDetails;
}

function getFlattenedShippingOptions(checkout: Checkout): ShippingOption[] {
  return (
    checkout.shippingInfo?.carrierServiceOptions?.flatMap(
      ({shippingOptions}) => shippingOptions ?? /* istanbul ignore next */ []
    ) ?? []
  );
}

function getOptionModels(options: ShippingOption[]): ShippingOptionModel[] {
  const optionsByCode = options.reduce<{[code: string]: ShippingOption[]}>(
    (acc, option) => ({...acc, [scheduledDeliveryKey(option)]: [...(acc[scheduledDeliveryKey(option)] ?? []), option]}),
    {}
  );

  return Object.keys(optionsByCode).map((code) => {
    const [option] = optionsByCode[code];
    if (optionsByCode[code].length === 1) {
      return new ShippingOptionModel(option);
    }
    return new ShippingOptionModel(option, optionsByCode[code]);
  });
}

const scheduledDeliveryKey = (option: ShippingOption) =>
  `${option.title!}-${option.cost?.price?.amount ?? /* istanbul ignore next */ ''}`;
