import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutService} from '../services/CheckoutService';
import {NavigationService} from '../services/NavigationService';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import {IWidgetControllerConfig} from '@wix/native-components-infra';

type CheckoutSlotApi = {
  isMethodSupported: (methodName: string) => boolean;
  checkoutId?: string;
  onUpdateCheckout: (callback: () => Promise<void>) => void;
};

export class SlotsStore {
  private readonly slotAPIFactory!: ReturnType<typeof createSlotVeloAPIFactory>;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly updateComponent: () => void;

  constructor({
    controllerConfig,
    siteStore,
    checkoutService,
    updateComponent,
    navigationService,
  }: {
    controllerConfig: IWidgetControllerConfig;
    siteStore: SiteStore;
    checkoutService: CheckoutService;
    updateComponent: () => void;
    navigationService: NavigationService;
  }) {
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.navigationService = navigationService;
    this.slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
  }

  /* istanbul ignore next: test slot */
  private readonly setSlotsParams = (slotId: string): void => {
    const slotApi = this.slotAPIFactory.getSlotAPI(slotId) as CheckoutSlotApi;
    slotApi.checkoutId = this.navigationService.appSectionParams.checkoutId;
    slotApi.onUpdateCheckout(async () => {
      await this.checkoutService.fetchCheckout();
      this.updateComponent();
    });
  };

  public toProps = () => {
    return {
      setSlotsParams: this.setSlotsParams,
    };
  };
}
